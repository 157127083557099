<template>
    <div>
        <v-data-table
            :disable-pagination="disablePagination"
            :items-per-page="itemsPerPage"
            :hide-default-footer="disablePagination"
            :headers="headers"
            :height="LocalSalesInvoiceItems.length < 2 ? '170px' : null"
            :items="LocalSalesInvoiceItems"
            calculate-widths
            class="mt-3 appic-table-light specification-table"
            dense
            disable-sort
            hide-default-header
            item-key="index"
            single-select
            v-if="itemsLoaded"
        >
            <template v-slot:header="{ props: { headers } }">
                <tr>
                    <th class="grey lighten-3 pa-1 text-center width-1-pct">{{ headers[0].text }}</th>
                    <th class="grey lighten-3 pa-1 text-left">{{ headers[1].text }}</th>
                    <th class="grey lighten-3 pa-1 text-left width-40-pct">
                        <v-row no-gutters>
                            <v-col cols="6">{{ $t('message.product') }}</v-col>
                            <v-col cols="6" class="pl-1">{{ $t('message.spec') }}</v-col>
                        </v-row>
                    </th>
                    <th class="grey lighten-3 pa-1 text-left width-40-pct">
                        <v-row no-gutters>
                            <v-col cols="6">{{ $t('message.size') }}</v-col>
                            <v-col cols="6" class="pl-1">{{ $t('message.quantity') }}</v-col>
                        </v-row>
                    </th>
                    <th class="grey lighten-3 pa-1 text-left width-15-pct">{{ headers[4].text }}</th>
                    <th class="grey lighten-3 pa-1 text-left width-10-pct">{{ headers[5].text }}</th>
                    <th class="grey lighten-3 pa-1 text-left width-5-pct">{{ headers[6].text }}</th>
                    <th class="grey lighten-3 pa-1 text-center width-1-pct">{{ headers[7].text }}</th>
                </tr>
            </template>
            <template v-slot:item.lineNumber="{ item }">
                <div class="text-center mt-2 font-16px">{{ item.lineNumber }}</div>
            </template>
            <template v-slot:item.Stock.bundleno="{ item }">
                <div class="font-weight-bold mt-2 text-no-wrap font-16px">{{ item.Stock.bundleno }}</div>
            </template>
            <template v-slot:item.Stock.productgroup_id="{ item }">
                <v-row no-gutters>
                    <v-col cols="6">
                        <template v-if="item.type == 'default'">
                            <div class="d-flex flex-row align-center">
                                <div style="min-width: 100px !important;" class="font-16px">{{ $t('message.product') + ":" }}</div>
                                <v-autocomplete
                                    :id="item.index + 'product'"
                                    :items=allProductGroups
                                    :placeholder="item.Stock.productgroup_id ? '' : $t('message.required')"
                                    :rules="[rules.product]"
                                    :value="item.Stock.productgroup_id"
                                    autocomplete="off"
                                    class="custom pa-1 bg-lightgrey"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    item-value="ProductGroup.id"
                                    item-text="ProductGroup.title"
                                    @input="updateLocalSalesInvoiceItemRowHandle( item.index, 'Stock.productgroup_id',$event)"
                                />
                            </div>
                            <div class="d-flex flex-row align-center">
                                <div style="min-width: 100px !important;" class="font-16px">{{ $t('message.species') + ":" }}</div>
                                <v-autocomplete
                                    :id="item.index + 'species'"
                                    :items="item.Options.species"
                                    :placeholder="item.Options.species.length > 0 ? $t('message.select') : $t('message.selectProductFirst')"
                                    :rules="[rules.species]"
                                    :value="item.Stock.species_id"
                                    autocomplete="off"
                                    class="custom pa-1 bg-lightgrey"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    item-value="Species.id"
                                    item-text="Species.title"
                                    return-object
                                    @input="updateLocalSalesInvoiceItemRowHandle( item.index, 'Stock.species_id',$event)"
                                />
                            </div>
                            <div class="d-flex flex-row align-center">
                                <div style="min-width: 100px !important;" class="font-16px">{{ $t('message.certification') + ":" }}</div>
                                <v-select
                                    :id="item.index + 'certification'"
                                    :items="allCertifications"
                                    :placeholder="$t('message.select')"
                                    :rules="[rules.certification]"
                                    :value="item.Stock.certification_id"
                                    autocomplete="off"
                                    class="custom pa-1 bg-lightgrey"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    item-text="Certification.title"
                                    item-value="Certification.id"
                                    @change="updateLocalSalesInvoiceItemRowHandle( item.index, 'Stock.certification_id',$event)"
                                />
                            </div>
                        </template>
                        <template v-if="item.type == 'othercharge'">
                            <v-text-field
                                :value="item.Stock.size"
                                autocomplete="off"
                                class="custom force-text-left pa-1 bg-lightgrey"
                                clearable
                                dense
                                hide-details="auto"
                                @change="updateLocalSalesInvoiceItemRowHandle( item.index, 'Stock.size',$event)"
                            />
                        </template>
                    </v-col>
                    <v-col cols="6" class="pl-1">
                        <template v-if="item.type == 'default'">
                            <div class="d-flex flex-row align-center" v-if="item.type == 'default'">
                                <div style="min-width: 50px !important;" class="font-16px">{{ $t('message.grade') + ":" }}</div>
                                <v-autocomplete
                                    :id="item.index + 'grade'"
                                    :items="item.Options.grades"
                                    :placeholder="$t('message.startTypingToSearch')"
                                    :value="item.Stock.grade_id"
                                    autocomplete="off"
                                    class="custom pa-1 bg-lightgrey"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    item-text="Grade.title"
                                    item-value="Grade.id"
                                    return-object
                                    @focus="currentRowIndex = item.index"
                                    @change="updateLocalSalesInvoiceItemRowHandle( item.index, 'Stock.grade_id',$event)"
                                >
                                    <template v-slot:selection="{ item }">
                                        <div class="text-wrap" style="width: 100%">{{ item.Grade.title }}</div>
                                    </template>
                                </v-autocomplete>
                            </div>
                            <div class="d-flex flex-row align-center">
                                <div style="width: 50px" class="font-16px">{{ $t('message.spec') + ":" }}</div>
                                <v-combobox
                                    :id="item.index + 'spec'"
                                    :items="item.Options.specs"
                                    :placeholder="item.Options.specs.length > 0 ? $t('message.select') : $t('message.selectProductFirst')"
                                    :value="item.Spec.title"
                                    class="custom pa-1 bg-lightgrey"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    item-value="Spec.id"
                                    item-text="Spec.title"
                                    return-object
                                    @focus="currentRowIndex = item.index"
                                    @change="updateLocalSalesInvoiceItemRowHandle( item.index, 'Stock.spec_id',$event)"
                                />
                            </div>
                            <div class="d-flex flex-row align-center" v-if="item.Stock.productgroup_id != 15">
                                <div style="width: 50px" class="font-16px">{{ $t('message.mc') + ":" }}</div>
                                <v-select
                                    :id="item.index + 'mc'"
                                    :items="allMoistureContent"
                                    :value="item.Stock.mc_id"
                                    class="custom pa-1 bg-lightgrey"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    item-text="Mc.title"
                                    item-value="Mc.id"
                                    @focus="currentRowIndex = item.index"
                                    @change="updateLocalSalesInvoiceItemRowHandle( item.index, 'Stock.mc_id',$event)"
                                />
                            </div>
                        </template>
                    </v-col>
                </v-row>
                <div class="d-flex flex-row align-center" style="height: 35px" v-if="item.type == 'default'">
                    <div class="font-16px">{{ $t('message.specificationAsShownInvoice') + ": " + item.Stock.salesdescription }}</div>
                    <v-btn icon @click="loading.resetUiDescription = true; loading.resetUiDescriptionIndex = item.index; resetUiDescription(item.index)" class="ml-2" :loading="loading.resetUiDescription && loading.resetUiDescriptionIndex == item.index">
                        <v-icon class="green--text text--darken-1">refresh</v-icon>
                    </v-btn>
                </div>
				<template v-if="updateMode == false && item.Errors && item.Errors.length > 0">
					<v-alert class="mt-2 font-16px" dense text type="warning" icon="warning">
						<div class="d-flex flex-column">
							<template v-for="error in item.Errors">
								<span class="black--text">{{ error }} </span>
							</template>
							<span class="black--text">{{ $t('message.errors.soCannotConvert') }}</span>
						</div>
					</v-alert>
				</template>
            </template>
            <template v-slot:item.Stock.size_id="{ item }">
                <template v-if="item.type == 'default'">
                    <v-row no-gutters>
                        <v-col cols="6">
                            <div class="d-flex flex-row align-center">
                                <div style="min-width: 100px !important;" class="font-16px">{{ $t('message.thickness') + ":" }}</div>
                                <v-autocomplete
                                    :id="item.index + 'thickness'"
                                    :items="allThickness"
                                    :placeholder="$t('message.select')"
                                    :value="item.Stock.thickness_id"
                                    class="custom pa-1 bg-lightgrey"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    item-text="Thickness.title"
                                    item-value="Thickness.id"
                                    @focus="currentRowIndex = item.index"
                                    @click="filterThickness(LocalSalesInvoice__ms_id)"
                                    @change="updateLocalSalesInvoiceItemRowHandle( item.index, 'Stock.thickness_id',$event)"
                                />
                            </div>
                            <div class="d-flex flex-row align-center">
                                <div style="min-width: 100px !important;" class="font-16px">{{ $t('message.width') + ":" }}</div>
                                <v-text-field
                                    :id="item.index + 'width'"
                                    :placeholder="$t('message.freeText')"
                                    :value="item.Stock.width"
                                    class="custom pa-1 bg-lightgrey force-text-left"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    @change="updateLocalSalesInvoiceItemRowHandle( item.index, 'Stock.width',$event)"
                                />
                            </div>
                            <div class="d-flex flex-row align-center">
                                <div style="min-width: 100px !important;" class="font-16px">{{ $t('message.length') + ":" }}</div>
                                <v-text-field
                                    :id="item.index + 'length'"
                                    :placeholder="$t('message.freeText')"
                                    :value="item.Stock.length"
                                    autocomplete="off"
                                    clearable
                                    class="custom pa-1 bg-lightgrey force-text-left"
                                    dense
                                    hide-details="auto"
                                    type="text"
                                    @change="updateLocalSalesInvoiceItemRowHandle( item.index, 'Stock.length',$event)"
                                />
                            </div>
                        </v-col>
                        <v-col cols="6" class="pl-1">
                            <div class="d-flex flex-row align-center">
                                <div style="min-width: 50px !important;" class="font-16px">{{ $t('message.pcs') + ":" }}</div>
                                <v-text-field
                                    :value="item.Stock.piece"
                                    autocomplete="off"
                                    class="custom pa-1 bg-lightgrey"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    type="number"
                                    @change="updateLocalSalesInvoiceItemRowHandle( item.index, 'Stock.piece',$event)"
                                />
                            </div>
                            <div class="d-flex flex-row align-center">
                                <div style="min-width: 50px !important;" class="font-16px">{{ $t('message.qty') + ":" }}</div>
                                <v-text-field
                                    :value="item.Stock.volume"
                                    autocomplete="off"
                                    class="custom pa-1 bg-lightgrey"
                                    clearable
                                    dense
                                    hide-details
                                    type="number"
                                    @change="updateLocalSalesInvoiceItemRowHandle( item.index, 'Stock.volume',$event)"
                                />
                            </div>
                            <div class="d-flex flex-row align-center">
                                <div style="min-width: 50px !important;" class="font-16px">{{ $t('message.unit') + ":" }}</div>
                                <v-select
                                    :items="uofmQty"
                                    :value="item.Stock.soldmeasurement_id"
                                    autocomplete="off"
                                    class="custom pa-1 bg-lightgrey"
                                    dense
                                    hide-details="auto"
                                    item-value="Measurement.id"
                                    item-text="Measurement.title"
                                    reverse
                                    @change="updateLocalSalesInvoiceItemRowHandle( item.index, 'Stock.soldmeasurement_id',$event)"
                                />
                            </div>
                        </v-col>
                    </v-row>
                    <div class="d-flex flex-row align-center" style="height: 35px">
                        <div class="font-16px">{{ $t('message.sizeAsShownInvoice') + ": " + (item.Stock.size ? item.Stock.size : '') }}</div>
                    </div>
                </template>
            </template>
            <template v-slot:item.Stock.costprice="{ item }">
                <template v-if="item.type == 'default'">
                    <div class="d-flex flex-row align-center">
                        <div style="min-width: 100px !important;" class="font-16px">{{ $t('message.buyingPrice') + ":" }}</div>
                        <v-text-field
                            :value="item.Stock.costprice"
                            autocomplete="off"
                            class="custom pa-1 bg-lightgrey"
                            clearable
                            dense
                            hide-details="auto"
                            type="number"
                            @change="updateLocalSalesInvoiceItemRowHandle( item.index, 'Stock.costprice',$event)"
                        />
                        <div class="ml-1 mt-1 font-16px">{{ getCurrencyCode(item.Stock.StockOrder.currency_id) }}</div>
                    </div>
                    <div class="d-flex flex-row align-center">
                        <div style="min-width: 100px !important;" class="font-16px">{{ $t('message.importDuty') + ":" }}</div>
                        <v-text-field
                            :value="item.Stock.importduty"
                            autocomplete="off"
                            class="custom pa-1 bg-lightgrey"
                            clearable
                            dense
                            hide-details="auto"
                            type="number"
                            @change="updateLocalSalesInvoiceItemRowHandle( item.index, 'Stock.importduty',$event)"
                        />
                        <div class="ml-1 mt-1 font-16px">{{ getCurrencyCode(item.Stock.StockOrder.currency_id) }}</div>
                    </div>
                    <div class="d-flex flex-row align-center">
                        <div style="min-width: 100px !important;" class="font-16px">{{ $t('message.storageCost') + ":" }}</div>
                        <v-text-field
                            :value="item.Stock.warehousecost"
                            autocomplete="off"
                            class="custom pa-1 bg-lightgrey"
                            clearable
                            dense
                            hide-details="auto"
                            type="number"
                            @change="updateLocalSalesInvoiceItemRowHandle( item.index, 'Stock.warehousecost',$event)"
                        />
                        <div class="ml-1 mt-1 font-16px">{{ getCurrencyCode(item.Stock.StockOrder.currency_id) }}</div>
                    </div>
                    <div class="d-flex flex-row align-center pb-2">
                        <div style="min-width: 100px !important;" class="font-16px">{{ $t('message.freightCost') + ":" }}</div>
                        <v-text-field
                            :value="item.Stock.forwardingcharge"
                            autocomplete="off"
                            class="custom pa-1 bg-lightgrey"
                            clearable
                            dense
                            hide-details="auto"
                            type="number"
                            @change="updateLocalSalesInvoiceItemRowHandle( item.index, 'Stock.forwardingcharge',$event)"
                        />
                        <div class="ml-1 mt-1 font-16px">{{ getCurrencyCode(item.Stock.StockOrder.currency_id) }}</div>
                    </div>
                </template>
            </template>
            <template v-slot:item.Stock.sellingprice="{ item }">
                <div class="d-flex flex-row">
                    <v-text-field
                        :value="item.Stock.sellingprice"
                        autocomplete="off"
                        class="custom pa-1 bg-lightgrey"
                        clearable
                        dense
                        hide-details="auto"
                        type="number"
                        @change="updateLocalSalesInvoiceItemRowHandle( item.index, 'Stock.sellingprice',$event)"
                    />
                    <span class="ml-1 mt-2 font-16px">{{ currency(LocalSalesInvoice__currency_id) }}</span>
                </div>
            </template>
            <template v-slot:item.Stock.amount="{ item }">
                <div class="mt-2 text-no-wrap text-right font-16px">{{ formatThisNumber(item.Stock.amount, findCurrencyPrecision(LocalSalesInvoice__currency_id)) + " " + currency(LocalSalesInvoice__currency_id) }}</div>
            </template>
            <template v-slot:item.Stock.id="{ item }">
                <div class="ml-2" v-if="item.type == 'othercharge'">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn icon @click="deleteItem(item.index, item.Stock.id)" v-on="on">
                                <v-icon color="grey">delete</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('message.deleteThisItem') }}</span>
                    </v-tooltip>
                </div>
                <div v-else class="mt-2 text-center font-weight-bold font-16px">{{ $t('message.na') }}</div>
            </template>
            <template v-slot:footer>
                <div class="pt-0 d-flex justify-space-between">
                    <div class="col-4 col-lg-4 col-md-3 pt-5 px-0 d-flex justify-start">
                        <v-btn
                            @click="addOtherChargesItemRow(statePrefix)"
                            color="success"
                            small
                        >{{ $t('message.addOtherCharges') }}
                        </v-btn>
                    </div>
                    <div class="dense-inputs col-8 col-lg-8 col-md-9 pa-0">
                        <v-row justify="end">
                            <v-col cols="1" lg="2" md="2" xs="3" class="pl-1 pr-3 pb-0">
                                <div class="text-right font-weight-black">{{ $t('message.totalPieces') }}</div>
                                <div class="text-right font-weight-black">{{ itemsTotalPieces }}</div>
                            </v-col>
                            <v-col cols="2" lg="2" md="2" xs="3" class="px-1 pb-0">
                                <div class="text-right font-weight-black">{{ $t('message.totalVolAndQty') }}</div>
                                <div class="text-right font-weight-black d-flex flex-column">
                                        <span v-for="(total,index) in itemsTotalQty" :class="itemsTotalQty.length > 1 && index == 0  ? 'pt-1' : ''">
                                            {{ formatThisNumber(total.Total.qty,uofmPrecision(total.Total.uofm)) + ' '+ total.Total.uofm }}
                                        </span>
                                </div>
                            </v-col>
                            <v-col cols="1" lg="2" md="2" xs="3" class="px-1 pb-0">
                                <div class="text-right font-weight-black">{{ $t('message.currency') }}</div>
                                <v-container class="ma-0 pa-0">
                                    <v-row no-gutters>
                                        <v-col cols="9" offset="3">
                                            <v-select
                                                :items="allCurrencies"
                                                class="custom py-0 mt-0 bg-lightgrey"
                                                hide-details
                                                item-text="Currency.code"
                                                item-value="Currency.id"
                                                reverse
                                                v-model="LocalSalesInvoice__currency_id"
                                            >
                                            </v-select>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-col>
                            <v-col cols="1" lg="2" md="2" xs="3" class="px-1 pb-0">
                                <div class="text-right font-weight-black">
                                    <span v-if="LocalSalesInvoice__currency_id == 2">{{ allCurrencies.find(c => c.Currency.id == Office__currency_id).Currency.code + ':' + allCurrencies.find( c => c.Currency.id == 2).Currency.code + ' ' + $t('message.exRate') }}</span>
                                    <span v-else>{{ allCurrencies.find(c => c.Currency.id == LocalSalesInvoice__currency_id).Currency.code + ':' + allCurrencies.find( c => c.Currency.id == 2).Currency.code + ' ' + $t('message.exRate') }}</span>
                                </div>
                                <v-container class="ma-0 pa-0">
                                    <v-text-field
                                        :value="LocalSalesInvoice__gstrmrate"
                                        class="custom py-1 pb-2 mt-0 bg-lightgrey"
                                        dense
                                        hide-details
                                        type="number"
                                        @change="LocalSalesInvoice__gstrmrate = $event"
                                    >
                                    </v-text-field>
                                </v-container>
                            </v-col>
                            <v-col cols="1" lg="2" md="2" xs="3" class="pl-1 pr-3 pb-0">
                                <div class="text-right font-weight-black">{{ $t('message.totalAmount') + '(' + allCurrencies.find(c => c.Currency.id == 2).Currency.code +')' }}</div>
                                <div class="text-right font-weight-black">
                                    <span v-if="LocalSalesInvoice__currency_id == 2">{{ formatThisNumber((itemsTotalAmount),currencyFormatPrecision) }}</span>
                                    <span v-else>{{ formatThisNumber((itemsTotalAmount / LocalSalesInvoice__gstrmrate),currencyFormatPrecision) }}</span>
                                </div>
                            </v-col>
                            <v-col cols="1" lg="2" md="2" xs="3" class="pl-1 pr-3 pb-0">
                                <div class="text-right font-weight-black">
                                    <span v-if="LocalSalesInvoice__currency_id == 2">{{ $t('message.totalAmount') + '(' + allCurrencies.find(c => c.Currency.id == Office__currency_id).Currency.code +')' }}</span>
                                    <span v-else>{{ $t('message.totalAmount') + '(' + allCurrencies.find(c => c.Currency.id == LocalSalesInvoice__currency_id).Currency.code +')' }}</span>
                                </div>
                                <div class="text-right font-weight-black">
                                    <span v-if="LocalSalesInvoice__currency_id == 2">{{ formatThisNumber(itemsTotalAmount * LocalSalesInvoice__gstrmrate,currencyFormatPrecision) }}</span>
                                    <span v-else>{{ formatThisNumber(itemsTotalAmount,currencyFormatPrecision) }}</span>
                                </div>
                            </v-col>
                        </v-row>
                    </div>
                </div>
            </template>
        </v-data-table>
    </div>
</template>

<script>
    import {mapFields} from "vuex-map-fields";
    import {mapFieldsPrefixed, mapMultiRowFieldsPrefixed} from "Helpers/vuex-map-fields-prefixed";
    import {mapActions, mapGetters, mapMutations} from "vuex";
    import {generateDimension, formatDate, generateSpec, numberFormat, log} from "Helpers/helpers";
    import LoadingItems from "Components/Appic/LoadingItems";

    export default {
        name: "LocalSalesInvoiceItemListV2",
        components: {LoadingItems},
        props: ['updateMode'],
        data () {
            return {
                currentRowIndex: 0,
                dialogs: {
                    confirm:  false,
                    confirm_message: ""
                },
                disablePagination: true,
                filteredUnits: [],
                itemsPerPage: -1,
                loading: {
                    addOtherCharge: false,
                    resetUiDescription: false,
                    resetUiDescriptionIndex: -1
                },
                itemsLoaded: false,
                rules: {
                    certification: v => typeof v === "number" || this.$t('message.required'),
                    container: v => !!v || this.$t('message.required'),
                    grade: v => !!v || this.$t('message.required'),
                    itemQty: v => !!v || this.$t('message.required'),
                    product: v => !!v || this.$t('message.required'),
                    size: v => !!v || this.$t('message.required'),
                    spec: v => !!v || this.$t('message.required'),
                    species: v => !!v || this.$t('message.required'),
                    unit: v => !!v || this.$t('message.required'),
                    unitPrice: v => !!v || this.$t('message.required'),
                }
            }
        },
        computed: {
            statePrefix() {
                if (this.updateMode) {
                    return 'update'
                } else {
                    return 'current'
                }
            },
            ...mapFieldsPrefixed('localsalesinvoice', {
                LocalSalesInvoice__currency_id: 'LocalSalesInvoice.currency_id',
                LocalSalesInvoice__gstpercent: 'LocalSalesInvoice.gstpercent',
                LocalSalesInvoice__gstrmrate: 'LocalSalesInvoice.gstrmrate',
                LocalSalesInvoice__id: 'LocalSalesInvoice.id',
                LocalSalesInvoice__ms_id: 'LocalSalesInvoice.ms_id',
                LocalSalesInvoice__office_id: 'LocalSalesInvoice.office_id',
                LocalSalesInvoice__salestatus_id: 'LocalSalesInvoice.salestatus_id',
            },'statePrefix'),
            ...mapFields('localsalesinvoice',{
                newLocalSalesInvoiceSaved: 'newLocalSalesInvoiceSaved',
                newLocalSalesInvoiceUnsaved: 'newLocalSalesInvoiceUnsaved',
                updatedLocalSalesInvoiceUnsaved: 'updatedLocalSalesInvoiceUnsaved',
                updatedLocalSalesInvoiceSaved: 'updatedLocalSalesInvoiceSaved'
            }),
            ...mapMultiRowFieldsPrefixed('localsalesitem',{
                LocalSalesInvoiceItems: 'LocalSalesInvoiceItems',
            },'statePrefix'),
            ...mapFieldsPrefixed('localsalesitem',{
                gstpercent: 'gstpercent',
                itemsCurrencyRate: 'itemsCurrencyRate',
                itemsTotalAmount: 'itemsTotalAmount',
                itemsTotalPieces: 'itemsTotalPieces',
                itemsTotalQty: 'itemsTotalQty'
            },'statePrefix'),
            ...mapGetters('certification',{
                allCertifications: 'allCertifications'
            }),
            ...mapGetters('currency',{
                allCurrencies: 'allCurrencies'
            }),
            ...mapGetters('grade',{
                allGrades: 'allGrades',
                findGradeById: 'findGradeById'
            }),
            ...mapGetters('mc', {
                allMoistureContent: 'allMoistureContent'
            }),
            ...mapGetters('productgroup',{
                allProductGroups: 'allProductGroups'
            }),
            ...mapGetters('size',{
                allSizes: 'allSizes',
                findSizeById: 'findSizeById'
            }),
            ...mapGetters('species', {
                allSpecies: 'allSpecies',
                findSpeciesById: 'findSpeciesById'
            }),
            ...mapGetters('spec',{
                allSpecs: 'allSpecs',
                allCleanedSpecs: 'allCleanedSpecs',
                findSpecById: 'findSpecById'
            }),
            ...mapGetters('thickness', {
                allThickness: 'allThickness'
            }),
            ...mapGetters([
                // 'certificationsNew',
                // 'currencies',
                'offices',
                // 'productGroups',
                'uofmDimensions',
                'uofmQty'
            ]),
            currencyFormatPrecision () {
                return this.allCurrencies.find((currency) => currency.Currency.id === this.LocalSalesInvoice__currency_id)?.Currency?.formatPrecision
            },
            headers() {
                return [
                    {
                        id: 1,
                        text: this.$t('message.lineNo'),
                        value: 'lineNumber'
                    },
                    {
                        id: 2,
                        text: this.$t('message.bundle'),
                        value: 'Stock.bundleno'
                    },
                    {
                        id: 3,
                        text: this.$t('message.product'),
                        value: 'Stock.productgroup_id'
                    },
                    {
                        id: 4,
                        text: this.$t('message.size'),
                        value: 'Stock.size_id'
                    },
                    {
                        id: 5,
                        text: this.$t('message.costPrice'),
                        class: 'force-text-right',
                        value: 'Stock.costprice'
                    },
                    {
                        id: 6,
                        text: this.$t('message.salesPrice'),
                        class: 'force-text-right',
                        value: 'Stock.sellingprice'
                    },
                    {
                        id: 7,
                        text: this.$t('message.amount'),
                        class: 'force-text-right',
                        value: 'Stock.amount'
                    },
                    {
                        id: 8,
                        text: this.$t('message.actions'),
                        value: 'Stock.id'
                    }
                ]
            },
            Office__currency_id () {
                let office = this.offices.find(o => o.Office.id == this.LocalSalesInvoice__office_id)
                return office.Office.currency_id
            },
        },
        methods: {
            ...mapActions('certification',{
                getAllCertifications: 'getAllCertifications'
            }),
            ...mapActions('currency',{
                getAllCurrencies: 'getAllCurrencies'
            }),
            ...mapActions('localsalesitem',{
                addOtherChargesItemRow: 'addOtherChargesItemRow',
                deleteLocalSalesInvoiceItem: 'deleteLocalSalesInvoiceItem',
                updateTotals: 'updateTotals'
            }),
            ...mapActions('grade', {
                getAllGrades: 'getAllGrades'
            }),
            ...mapActions('mc', {
                getAllMoistureContent: 'getAllMoistureContent'
            }),
            ...mapActions('productgroup', {
                getAllProductGroups: 'getAllProductGroups'
            }),
            ...mapActions('size', {
                getAllSizes: 'getAllSizes'
            }),
            ...mapActions('spec', {
                getAllSpecs: 'getAllSpecs',
                getAllCleanedSpecs: 'getAllCleanedSpecs'
            }),
            ...mapActions('species', {
                getAllSpecies: 'getAllSpecies'
            }),
            ...mapActions('thickness', {
                getAllThickness: 'getAllThickness'
            }),
            ...mapMutations('localsalesitem',{
                deleteLocalSalesInvoiceItemRow: 'deleteLocalSalesInvoiceItemRow',
            }),
            currency(currency_id) {
                const currency = this.allCurrencies.find((currency) => currency.Currency.id == currency_id)
                if(currency){
                    return currency.Currency.code
                }
                return ''
            },
            async deleteItem (itemIndex, itemId) {
                if (await this.$root.$confirm(this.$t('message.delete'), this.$t('message.confirmations.continueDeleteItemAction'), {color: 'red'})) {
                    let promise = new Promise((resolve, reject) => {
                        let payload = {
                            index: itemIndex,
                            prefix: this.statePrefix
                        }
                        this.deleteLocalSalesInvoiceItemRow(payload)
                        resolve('ok')
                    });
                    promise.then(() => {
                        if(itemId != null){
                            this.deleteLocalSalesInvoiceItem({
                                localSalesInvoiceId: this.LocalSalesInvoice__id,
                                itemId: itemId}
                            )
                                .then((response)=>{
                                    this.updateTotals( this.statePrefix )
                                })
                        } else {
                            this.updateTotals( this.statePrefix )
                        }
                    })
                }
            },
            findCurrencyPrecision (val) {
                let currency = this.allCurrencies.find(c => c.Currency.id == val)
                return currency?.Currency?.formatPrecision
            },
            filterGradesByProduct(productId){
                let filteredGrades = this.allGrades.filter(grade => {
                    return grade.Grade.productgroups.includes(productId)
                })
                if(filteredGrades.length > 0) {
                    return filteredGrades
                } else {
                    return []
                }
            },
            filterSpeciesByProduct(productId){
                let filteredSpecies = this.allSpecies.filter(species => {
                    return species.Species.productgroups.includes(productId)
                })
                if (filteredSpecies.length > 0) {
                    return filteredSpecies
                } else {
                    return []
                }
            },
            filterSpecsByProduct(productId){
                let filteredSpecs = this.allCleanedSpecs.filter(spec => {
                    return spec.Spec.productgroups.includes(productId)
                })
                if(filteredSpecs.length > 0) {
                    return filteredSpecs
                } else {
                    return []
                }
            },
            filterThickness(ms) {
                let various = this.allThickness.find(t => {
                    return t.Thickness.ms == ms && t.Thickness.title == 'various'
                })
                let thicknesses = this.allThickness.filter(t => {
                    return t.Thickness.ms == ms && t.Thickness.title != 'various'
                })
                thicknesses.sort((a,b) => {
                    let strA = a + ''
                    let strB = b + ''
                    return strA.localeCompare(strB, undefined, { numeric: true })
                })
                if(various){
                    this.filteredThickness = [various,...thicknesses]
                } else {
                    this.filteredThickness = thicknesses
                }
            },
            formatDate,
            formatThisNumber (value,format) {
                return numberFormat(value,format)
            },
            getCurrencyCode (currency_id) {
                let code = this.allCurrencies.find((currency) => currency.Currency.id == currency_id)?.Currency?.code
                return code !== undefined ? code : ''
            },
            getUiDescription(item) {
                let uiDescription = []

                const species = this.allSpecies.find(s => s.Species.id == item.Stock.species_id)
                if(species) uiDescription.push(species.Species.title)

                if(item.Stock.description != null) {
                    let product = this.allProductGroups.find(p => p.ProductGroup.id == item.Stock.productgroup_id)
                    if (product && item.Stock.description.includes(product.ProductGroup.title) == false) uiDescription.push(product.ProductGroup.title)
                } else {
                    let product = this.allProductGroups.find(p => p.ProductGroup.id == item.Stock.productgroup_id)
                    if (product) uiDescription.push(product.ProductGroup.title)
                }

                if(item.Stock.description != '') uiDescription.push(item.Stock.description)

                let certification = this.allCertifications.find(c => c.Certification.id == item.Stock.certification_id)
                if(certification && item.Stock.description != null && item.Stock.description.includes(certification.Certification.title) == false && certification.Certification.title != 'None') uiDescription.push(certification.Certification.title)
                if(certification && item.Stock.description == null && certification.Certification.title != 'None') uiDescription.push(certification.Certification.title)

                const mc = this.allMoistureContent.find(mc => mc.Mc.id == item.Stock.mc_id)
                if(mc && item.Stock.description == null) uiDescription.push(mc.Mc.title)

                uiDescription = uiDescription.filter(Boolean).join(", ")
                this.$store.commit('localsalesitem/updateLocalSalesInvoiceItems', {
                    index: item.index,
                    field: 'Stock.salesdescription',
                    prefix: this.statePrefix,
                    value: uiDescription
                })
                return uiDescription
            },
            resetUiDescription(itemIndex) {
                const item = this.LocalSalesInvoiceItems[itemIndex];
                let uiDescription = []

                const species = this.allSpecies.find(s => s.Species.id == item.Stock.species_id)
                if(species) uiDescription.push(species.Species.title)

                if(item.Stock.description != null) {
                    let product = this.allProductGroups.find(p => p.ProductGroup.id == item.Stock.productgroup_id)
                    if (product && item.Stock.description.includes(product.ProductGroup.title) == false) uiDescription.push(product.ProductGroup.title)
                } else {
                    let product = this.allProductGroups.find(p => p.ProductGroup.id == item.Stock.productgroup_id)
                    if (product) uiDescription.push(product.ProductGroup.title)
                }

                if(item.Stock.description != '') uiDescription.push(item.Stock.description)

                let certification = this.allCertifications.find(c => c.Certification.id == item.Stock.certification_id)
                if(certification && item.Stock.description != null && item.Stock.description.includes(certification.Certification.title) == false && certification.Certification.title != 'None') uiDescription.push(certification.Certification.title)
                if(certification && item.Stock.description == null && certification.Certification.title != 'None') uiDescription.push(certification.Certification.title)

                const mc = this.allMoistureContent.find(mc => mc.Mc.id == item.Stock.mc_id)
                if(mc && item.Stock.description == null) uiDescription.push(mc.Mc.title)

                uiDescription = uiDescription.filter(Boolean).join(", ")
                this.$store.commit('localsalesitem/updateLocalSalesInvoiceItems', {
                    index: item.index,
                    field: 'Stock.salesdescription',
                    prefix: this.statePrefix,
                    value: uiDescription
                })
                this.loading.resetUiDescription = false
                this.loading.resetUiDescriptionIndex = -1
                return true
            },
            setItemDescription(idx) {
                if(idx != null) {
                    let product = null
                    let spec = null
                    let mc = null

                    let productGroupId = this.LocalSalesInvoiceItems[idx].Stock.productgroup_id
                    let specId = this.LocalSalesInvoiceItems[idx].Stock.spec_id
                    let mcId = this.LocalSalesInvoiceItems[idx].Stock.mc_id

                    let productObj = this.allProductGroups.find(pg => pg.ProductGroup?.id == productGroupId)
                    let specObj = this.allCleanedSpecs.find(s => s.Spec.id == specId)
                    if(specId == 'NEW'){
                        specObj = { Spec: this.LocalSalesInvoiceItems[idx]['Spec'] }
                    }
                    if(typeof specObj === 'undefined'){
                        specObj = { Spec: this.LocalSalesInvoiceItems[idx]['Spec'] }
                    }
                    let mcObj = this.allMoistureContent.find(mc => mc.Mc.id == mcId)

                    if(productObj) product = productObj?.Productgroup?.title
                    if(specObj && specObj?.Spec?.title != 'na') spec = specObj?.Spec?.title
                    if(mcObj) mc = mcObj?.Mc?.title

                    const description = generateSpec(product, spec, mc)
                    this.LocalSalesInvoiceItems[idx].Stock.description = description.trim()
                }
            },
            uofmPrecision (uofm) {
                if(uofm) {
                    if(isNaN(uofm)) {
                        return this.uofmQty.find((u) => u.Measurement.title == uofm.trim())?.Measurement?.formatPrecision
                    } else {
                        return this.uofmQty.find((u) => u.Measurement.id == uofm)?.Measurement?.formatPrecision
                    }
                } else {
                    return '0,0.00'
                }
            },
            updateEmptyOptions() {
                return new Promise((resolve, reject) => {
                    try {
                        this.LocalSalesInvoiceItems.forEach((item) => {
                            const productId = item.Stock.productgroup_id
                            const idx = item.index

                            if(item.Options.species.length == 0) {
                                //filter species
                                const species = this.filterSpeciesByProduct(productId)
                                const speciesPayload = {
                                    index: idx,
                                    field: 'Options.species',
                                    prefix: this.statePrefix,
                                    value: species
                                }
                                this.$store.commit('localsalesitem/updateLocalSalesInvoiceItems', speciesPayload)
                            }

                            if(item.Options.species.length == 0) {
                                //filter grades
                                const grades = this.filterGradesByProduct(productId)
                                const gradesPayload = {
                                    index: idx,
                                    field: 'Options.grades',
                                    prefix: this.statePrefix,
                                    value: grades
                                }
                                this.$store.commit('localsalesitem/updateLocalSalesInvoiceItems', gradesPayload)
                            }

                            if(item.Options.species.length == 0) {
                                //filter specs
                                const specs = this.filterSpecsByProduct(productId)
                                const specsPayload = {
                                    index: idx,
                                    field: 'Options.specs',
                                    prefix: this.statePrefix,
                                    value: specs
                                }
                                this.$store.commit('localsalesitem/updateLocalSalesInvoiceItems', specsPayload)
                            }
                        })
                        log('Updated empty options')
                        resolve('done')
                    } catch(error) {
                        reject(error)
                    }
                })
            },
            updateEmptySizes() {
                return new Promise((resolve, reject) => {
                    try {
                        this.LocalSalesInvoiceItems.forEach((item) => {
                            const idx = item.index
                            //update size_text
                            let thicknessId = this.LocalSalesInvoiceItems[idx].Stock.thickness_id
                            let width = this.LocalSalesInvoiceItems[idx].Stock.width
                            let length = this.LocalSalesInvoiceItems[idx].Stock.length

                            let thicknessObj = this.allThickness.find(t => t.Thickness.id == thicknessId)
                            let thickness = thicknessObj?.Thickness.title
                            let unit = 'mm'
                            if(this.StockOrder__ms_id == 2){
                                unit = ''
                            }

                            const sizeText = generateDimension(thickness,width,length,unit)

                            if(item.Stock.size != sizeText) {
                                this.$store.commit('localsalesitem/updateLocalSalesInvoiceItems', {
                                    index: idx,
                                    field: 'Stock.size',
                                    prefix: this.statePrefix,
                                    value: sizeText
                                })

                                //update Size object
                                //check if size exists
                                let sizeId = null
                                let sizeObj = this.allSizes.find(s => s.Size.title == sizeText)
                                if (sizeObj) {
                                    sizeId = sizeObj?.Size.id
                                } else {
                                    if (sizeText.length > 0) {
                                        sizeId = 'NEW' //new size object
                                    }
                                }
                                this.$store.commit('localsalesitem/updateLocalSalesInvoiceItems', {
                                    index: idx,
                                    field: 'Size.id',
                                    prefix: this.statePrefix,
                                    value: sizeId
                                })
                                this.$store.commit('localsalesitem/updateLocalSalesInvoiceItems', {
                                    index: idx,
                                    field: 'Size.title',
                                    prefix: this.statePrefix,
                                    value: sizeText
                                })
                                this.$store.commit('localsalesitem/updateLocalSalesInvoiceItems', {
                                    index: idx,
                                    field: 'Stock.size_id',
                                    prefix: this.statePrefix,
                                    value: sizeId
                                })
                            }
                        })
                        log('Updated empty sizes')
                        resolve('done')
                    } catch (error){
                        reject(error)
                    }
                })
            },
            updateEmptySpecifications() {
                return new Promise((resolve, reject) => {
                    try {
                        this.LocalSalesInvoiceItems.forEach((item) => {
                            const idx = item.index
                            const specObj = this.allSpecs.find(s => s.Spec.id == item.Stock.spec_id)
                            // assign Spec property in LocalSalesItem
                            this.$store.commit('localsalesitem/updateLocalSalesInvoiceItems', {
                                index: idx,
                                field: 'Spec.id',
                                prefix: this.statePrefix,
                                value: specObj?.Spec?.id
                            })
                            this.$store.commit('localsalesitem/updateLocalSalesInvoiceItems', {
                                index: idx,
                                field: 'Spec.title',
                                prefix: this.statePrefix,
                                value: specObj?.Spec?.title
                            })
                        })
                        log('Updated empty specs')
                        resolve('done')
                    } catch (error) {
                        reject(error)
                    }
                })
            },
            updateLocalSalesInvoiceItemRowHandle (idx,field,value) {
                switch(field) {
                    case 'Stock.spec_id':
                        if(value) {
                            if(value?.Spec?.id) { //value is an object
                                payload = {
                                    index: idx,
                                    field: field,
                                    prefix: this.statePrefix,
                                    value: value?.Spec?.id
                                }
                                // assign Spec property in LocalSalesItem
                                this.$store.commit('localsalesitem/updateLocalSalesInvoiceItems', {
                                    index: idx,
                                    field: 'Spec.id',
                                    prefix: this.statePrefix,
                                    value: value?.Spec?.id
                                })
                                this.$store.commit('localsalesitem/updateLocalSalesInvoiceItems', {
                                    index: idx,
                                    field: 'Spec.title',
                                    prefix: this.statePrefix,
                                    value: value?.Spec?.title
                                })

                                //update Stock.mc_id if empty and Spec.mc_id is not - VJC @ 2021-08-06
                                let specObj = this.allSpecs.find(s => s.Spec.id == value?.Spec?.id)
                                if(specObj && specObj?.Spec.mc_id != null) {
                                    this.LocalSalesInvoiceItems[idx]['Stock']['mc_id'] = specObj?.Spec?.mc_id
                                }

                            } else {
                                //value is a string - new spec
                                payload = {
                                    index: idx,
                                    field: field,
                                    prefix: this.statePrefix,
                                    value: 'NEW'
                                }
                                // assign Spec property in LocalSalesItem
                                this.$store.commit('localsalesitem/updateLocalSalesInvoiceItems', {
                                    index: idx,
                                    field: 'Spec.id',
                                    prefix: this.statePrefix,
                                    value: 'NEW'
                                })
                                this.$store.commit('localsalesitem/updateLocalSalesInvoiceItems', {
                                    index: idx,
                                    field: 'Spec.title',
                                    prefix: this.statePrefix,
                                    value: value
                                })
                            }
                        } else {
                            //if empty
                            payload = {
                                index: idx,
                                field: field,
                                prefix: this.statePrefix,
                                value: null
                            }
                            // assign Spec property in NcrItem
                            this.$store.commit('localsalesitem/updateLocalSalesInvoiceItems', {
                                index: idx,
                                field: 'Spec.id',
                                prefix: this.statePrefix,
                                value: null
                            })
                            this.$store.commit('localsalesitem/updateLocalSalesInvoiceItems', {
                                index: idx,
                                field: 'Spec.title',
                                prefix: this.statePrefix,
                                value: null
                            })
                        }
                        break;
                    case 'Stock.productgroup_id':
                        if(typeof value === 'undefined') value = null
                        payload = {
                            index: idx,
                            field: field,
                            prefix: this.statePrefix,
                            value: value,
                        }
                        //filter species
                        const species = this.filterSpeciesByProduct(value)
                        const speciesPayload = {
                            index: idx,
                            field: 'Options.species',
                            prefix: this.statePrefix,
                            value: species
                        }
                        this.$store.commit('localsalesitem/updateLocalSalesInvoiceItems', speciesPayload)

                        //filter grades
                        const grades = this.filterGradesByProduct(value)
                        const gradesPayload = {
                            index: idx,
                            field: 'Options.grades',
                            prefix: this.statePrefix,
                            value: grades
                        }
                        this.$store.commit('localsalesitem/updateLocalSalesInvoiceItems', gradesPayload)

                        //filter specs
                        const specs = this.filterSpecsByProduct(value)
                        const specsPayload = {
                            index: idx,
                            field: 'Options.specs',
                            prefix: this.statePrefix,
                            value: specs
                        }
                        this.$store.commit('localsalesitem/updateLocalSalesInvoiceItems', specsPayload)
                        break;
                    case 'Stock.soldmeasurement_id':
                        var payload = {
                            index: idx,
                            field: field,
                            prefix: this.statePrefix,
                            value: value,
                        }
                        //assign Soldmeasurement property in Stock
                        let measurement = this.uofmQty.filter(uofm => uofm.Measurement.id === value)[0];
                        if(measurement != null) {
                            this.$store.commit('localsalesitem/updateLocalSalesInvoiceItems', {
                                index: idx,
                                field: 'Soldmeasurement.id',
                                prefix: this.statePrefix,
                                value: measurement.Measurement.id != null ? measurement.Measurement.id : null
                            })
                            this.$store.commit('localsalesitem/updateLocalSalesInvoiceItems', {
                                index: idx,
                                field: 'Soldmeasurement.title',
                                prefix: this.statePrefix,
                                value: measurement.Measurement.title != null ? measurement.Measurement.title : null
                            })
                        }
                        break;
                    case 'Stock.species_id':
                        var payload = {
                            index: idx,
                            field: field,
                            prefix: this.statePrefix,
                            value: value?.Species?.id
                        }
                        // assign Species property in Stock
                        this.$store.commit('localsalesitem/updateLocalSalesInvoiceItems', {
                            index: idx,
                            field: 'Species.id',
                            prefix: this.statePrefix,
                            value: value?.Species?.id
                        })
                        this.$store.commit('localsalesitem/updateLocalSalesInvoiceItems', {
                            index: idx,
                            field: 'Species.title',
                            prefix: this.statePrefix,
                            value: value?.Species?.title
                        })
                        break;
                    case 'Stock.grade_id':
                        var payload = {
                            index: idx,
                            field: field,
                            prefix: this.statePrefix,
                            value: value?.Grade?.id
                        }
                        // assign Grade property in Stock
                        this.$store.commit('localsalesitem/updateLocalSalesInvoiceItems', {
                            index: idx,
                            field: 'Grade.id',
                            prefix: this.statePrefix,
                            value: value?.Grade?.id
                        })
                        this.$store.commit('localsalesitem/updateLocalSalesInvoiceItems', {
                            index: idx,
                            field: 'Grade.title',
                            prefix: this.statePrefix,
                            value: value?.Grade?.title
                        })
                        break;
                    case 'Stock.thickness_id':
                    case 'Stock.width':
                    case 'Stock.length':
                        if(typeof value === 'undefined') value = null

                        //update size_text
                        let thicknessId = this.LocalSalesInvoiceItems[idx].Stock.thickness_id
                        let width = this.LocalSalesInvoiceItems[idx].Stock.width
                        let length = this.LocalSalesInvoiceItems[idx].Stock.length
                        if(field == 'Stock.thickness_id'){
                            thicknessId = value
                        }
                        if(field == 'Stock.width'){
                            width = value
                        }
                        if(field == 'Stock.length'){
                            length = value
                        }

                        let thicknessObj = this.allThickness.find(t => t.Thickness.id == thicknessId)
                        let thickness = thicknessObj?.Thickness.title
                        let unit = 'mm'
                        if(this.StockOrder__ms_id == 2){
                            unit = ''
                        }

                        const sizeText = generateDimension(thickness,width,length,unit)
                        this.$store.commit('localsalesitem/updateLocalSalesInvoiceItems', {
                            index: idx,
                            field: 'Stock.size',
                            prefix: this.statePrefix,
                            value: sizeText
                        })

                        //update Size object
                        //check if size exists
                        let sizeId = null
                        let sizeObj = this.allSizes.find(s => s.Size.title == sizeText)
                        if(sizeObj){
                            sizeId = sizeObj?.Size.id
                        } else {
                            if (sizeText.length > 0) {
                                sizeId = 'NEW' //new size object
                            }
                        }
                        this.$store.commit('localsalesitem/updateLocalSalesInvoiceItems', {
                            index: idx,
                            field: 'Size.id',
                            prefix: this.statePrefix,
                            value: sizeId
                        })
                        this.$store.commit('localsalesitem/updateLocalSalesInvoiceItems', {
                            index: idx,
                            field: 'Size.title',
                            prefix: this.statePrefix,
                            value: sizeText
                        })
                        this.$store.commit('localsalesitem/updateLocalSalesInvoiceItems', {
                            index: idx,
                            field: 'Stock.size_id',
                            prefix: this.statePrefix,
                            value: sizeId
                        })
                        var payload = {
                            index: idx,
                            field: field,
                            prefix: this.statePrefix,
                            value: value,
                        }
                        break;
                    default:
                        var payload = {
                            index: idx,
                            field: field,
                            prefix: this.statePrefix,
                            value: value,
                        }
                }
                let promise = new Promise((resolve, reject) => {
                    try {
                        this.$store.commit('localsalesitem/updateLocalSalesInvoiceItems', payload)
                        resolve('ok')
                    } catch (error) {
                        reject(error)
                    }
                });
                promise
                    .then(()=>{
                        this.updateTotals(this.statePrefix)
                        if(['Stock.productgroup_id','Stock.spec_id','Stock.grade_id','Stock.mc_id'].includes(field)){
                            this.setItemDescription(idx)
                        }
                        if(['Stock.productgroup_id','Stock.species_id','Stock.certification_id','Stock.spec_id','Stock.mc_id'].includes(field)){
                            this.resetUiDescription(idx)
                        }
                        log('Item updated...')
                    })
            }
        },
        watch: {},
        created () {
            if(this.LocalSalesInvoiceItems.length > 20){
                this.disablePagination = false
                this.itemsPerPage = 10
            }
            if(this.allCurrencies.length == 0) this.getAllCurrencies()
            if(this.allSpecies.length == 0) this.getAllSpecies()
            if(this.allGrades.length == 0) this.getAllGrades()
            if(this.allCertifications.length == 0) this.getAllCertifications()
            if(this.allProductGroups.length == 0) this.getAllProductGroups()
            if(this.allCleanedSpecs.length == 0) this.getAllCleanedSpecs()
            if(this.allSizes.length == 0) this.getAllSizes()
            if(this.allThickness.length == 0) this.getAllThickness()
            if(this.allMoistureContent.length == 0) this.getAllMoistureContent()

            this.itemsCurrencyRate = this.LocalSalesInvoice__gstrmrate
            this.gstpercent = this.LocalSalesInvoice__gstpercent
        },
        mounted() {
            this.$store.watch(
                function (state) {
                    return state.appic.localsalesitem.update.LocalSalesInvoiceItems
                },
                () => {
                    this.updatedLocalSalesInvoiceUnsaved = true
                },
                {deep: true}
            )
            this.$store.watch(
                function (state) {
                    return state.appic.localsalesitem.current.LocalSalesInvoiceItems
                },
                () => {
                    this.newLocalSalesInvoiceUnsaved = true
                },
                {deep: true}
            )
            this.itemsLoaded = true
            const isAdd = this.$route.matched.some(({name}) => name == 'add_localsale')
            this.$nextTick(function () {
                this.filteredUnits = this.uofmQty.filter(u => {
                    return u.Measurement.system == this.LocalSalesInvoice__ms_id || u.Measurement.system == null
                })
                this.filterThickness(this.LocalSalesInvoice__ms_id)
                if(this.updateMode || isAdd) {
                    this.LocalSalesInvoiceItems.forEach((item, idx) => {
                        //filter species
                        let species = this.filterSpeciesByProduct(item.Stock.productgroup_id)
                        let speciesPayload = {
                            index: idx,
                            field: 'Options.species',
                            prefix: this.statePrefix,
                            value: species,
                        }
                        this.$store.commit('localsalesitem/updateLocalSalesInvoiceItems', speciesPayload)

                        //filter grades
                        let grades = this.filterGradesByProduct(item.Stock.productgroup_id)
                        let gradesPayload = {
                            index: idx,
                            field: 'Options.grades',
                            prefix: this.statePrefix,
                            value: grades,
                        }
                        this.$store.commit('localsalesitem/updateLocalSalesInvoiceItems', gradesPayload)

                        //filter specs
                        let specs = this.filterSpecsByProduct(item.Stock.productgroup_id)
                        let specsPayload = {
                            index: idx,
                            field: 'Options.specs',
                            prefix: this.statePrefix,
                            value: specs,
                        }
                        this.$store.commit('localsalesitem/updateLocalSalesInvoiceItems', specsPayload)
                    })
                }
            })
        },
        updated() {
            this.filteredUnits = this.uofmQty.filter(u => {
                return u.Measurement.system == this.LocalSalesInvoice__ms_id || u.Measurement.system == null
            })
            this.filterThickness(this.LocalSalesInvoice__ms_id)
            const isAdd = this.$route.matched.some(({name}) => name == 'add_localsale')
            if (this.updateMode || isAdd) {
                this.LocalSalesInvoiceItems.forEach((item, idx) => {
                    //filter species
                    let species = this.filterSpeciesByProduct(item.Stock.productgroup_id)
                    let speciesPayload = {
                        index: idx,
                        field: 'Options.species',
                        prefix: this.statePrefix,
                        value: species,
                    }
                    this.$store.commit('localsalesitem/updateLocalSalesInvoiceItems', speciesPayload)

                    //filter grades
                    let grades = this.filterGradesByProduct(item.Stock.productgroup_id)
                    let gradesPayload = {
                        index: idx,
                        field: 'Options.grades',
                        prefix: this.statePrefix,
                        value: grades,
                    }
                    this.$store.commit('localsalesitem/updateLocalSalesInvoiceItems', gradesPayload)

                    //filter specs
                    let specs = this.filterSpecsByProduct(item.Stock.productgroup_id)
                    let specsPayload = {
                        index: idx,
                        field: 'Options.specs',
                        prefix: this.statePrefix,
                        value: specs,
                    }
                    this.$store.commit('localsalesitem/updateLocalSalesInvoiceItems', specsPayload)
                })
            }
            this.itemsLoaded = true
        }
    }
</script>

<style>
.bg-lightgrey {
    background-color: #F5F5F5;
}
.v-text-field__slot input {
    text-align: right;
}
.custom.v-text-field {
    font-size: 0.75rem !important;
}
.custom.v-text-field>.v-input__control>.v-input__slot:before {
    border-style: none;
}
.custom.v-text-field>.v-input__control>.v-input__slot:after {
    border-style: none;
}
.v-data-table td {
    padding: 0 5px;
}
.font-xs {
    font-size: 0.65rem;
}
.custom .v-icon {
    font-size: 18px !important;
}
.truncate {
    width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.v-data-table tbody td {
    vertical-align: top !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
    height: 40px !important;
    font-size: 0.75rem !important;
}
.row-reverse { flex-direction: row-reverse; }
.force-text-left {
    text-align: left !important;
}
.width-18-pct {
    width: 18%;
}
.font-16px {
    font-size: 16px !important;
    line-height: 20px;
}
.dense-inputs .v-input {
    font-size: 16px !important;
}
</style>